<!-- xuwencheng  2022年6年25日11:00  这是我的活动添加列表 -->
<!-- 编辑 -->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="50%"
    @close="close"
  >
    <el-form
      ref="form"
      :label-position="labelPosition"
      label-width="200px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="姓名" prop="yName">
        <el-input
          class="el-input"
          v-model="form.yName"
          disabled
          placeholder="请输入讲师姓名"
        />
      </el-form-item>
      <el-form-item label="嘉宾类型" prop="userTypeName">
        <el-input
          v-model="form.userTypeName"
          disabled
          placeholder="请输入讲师姓名"
        />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input
          class="el-input"
          v-model="form.phone"
          disabled
          placeholder="请输入手机号"
        />
      </el-form-item>
      <el-form-item label="身份证" prop="idcard">
        <el-input class="el-input" v-model="form.idcard" disabled />
      </el-form-item>
      <el-form-item
        label="当前嘉宾未完成的任务"
        label-width="100px"
        prop="activityId"
      >
        <el-select
          v-model="form.activityId"
          size="small"
          placeholder="请选择任务"
        >
          <el-option
            v-for="item in plateList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="温度" prop="wendu">
        <el-input class="el-input" v-model="form.wendu" />
      </el-form-item>

      <el-form-item label="出发地" prop="startAddress">
        <div class="block">
          <el-cascader
            v-model="startAddress"
            ref="cascader"
            filterable
            :options="optionswo"
            placeholder="选择出发地"
            @change="cascar"
          />
        </div>
      </el-form-item>
      <el-form-item label="健康码" prop="yMa">
        <el-radio v-model="form.yMa" label="黄码">黄码</el-radio>
        <el-radio v-model="form.yMa" label="红码">红码</el-radio>
        <el-radio v-model="form.yMa" label="绿码">绿码</el-radio>
      </el-form-item>
      <el-form-item label="健康码图片" prop="healthImg">
        <el-upload
          class="avatar-uploader"
          :headers="{
            'X-Token': token,
          }"
          action="https://ybhpctest.gengduoke.com/adminapi/uploadToQiNiu"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrltwo" :src="imageUrltwo" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="七日旅居史">
        <div class="block">
          <el-cascader
            v-model="selectedIds"
            ref="cascadertwo"
            filterable
            :options="optionthree"
            placeholder="七日旅居史"
            @change="cascartwo"
            :props="props"
          />
        </div>
      </el-form-item>
      <el-form-item label="行程码图片" prop="journeyImg">
        <el-upload
          class="avatar-uploader"
          :headers="{
            'X-Token': token,
          }"
          action="https://ybhpctest.gengduoke.com/adminapi/uploadToQiNiu"
          :show-file-list="false"
          :on-success="hand"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrlthree" :src="imageUrlthree" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="核酸检测记录图片" prop="heSuanJianCeHistory">
        <el-upload
          class="avatar-uploader"
          :headers="{
            'X-Token': token,
          }"
          action="https://ybhpctest.gengduoke.com/adminapi/uploadToQiNiu"
          :show-file-list="false"
          :on-success="hesuan"
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="heSuanJianCeHistory"
            :src="heSuanJianCeHistory"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item
        label="7天内是否去过或接触过发生疫情的地级市和有扩散风险的毗邻地区及人员:"
        prop="jcs14"
      >
        <el-radio v-model="form.jcs14" label="是">是</el-radio>
        <el-radio v-model="form.jcs14" label="否">否</el-radio>
      </el-form-item>
      <el-form-item
        v-if="form.jcs14 == '是'"
        label="去过的发生疫情的地级市和有扩散风险的毗邻地区及人员"
        prop="jcs14Address"
      >
        <el-input class="el-input" v-model="form.jcs14Address" />
      </el-form-item>
      <el-form-item
        label="会议报道日前21天是否有境外旅居历史、是否有密切接触"
        prop="jcs21"
      >
        <el-radio v-model="form.jcs21" label="有境外旅居史"
          >有境外旅居史</el-radio
        >
        <el-radio v-model="form.jcs21" label="存在接触史">存在接触史</el-radio>
        <el-radio v-model="form.jcs21" label="都没有">都没有</el-radio>
      </el-form-item>
      <el-form-item
        v-if="form.jcs21 == '有境外旅居史'"
        label="旅居历史"
        prop="jcs21Address"
      >
        <el-input class="el-input" v-model="form.jcs21Address" />
      </el-form-item>
      <el-form-item
        v-if="form.jcs21 == '存在接触史'"
        label="接触史"
        prop="jcs21Address"
      >
        <el-input class="el-input" v-model="form.jcs21Address" />
      </el-form-item>
      <el-form-item label="居住社区21天内发生疫情" prop="ySq">
        <el-radio v-model="form.ySq" :label="1">是</el-radio>
        <el-radio v-model="form.ySq" :label="-1">否</el-radio>
      </el-form-item>
      <el-form-item label="属于下面哪种情形" prop="ySzh">
        <el-radio v-model="form.ySzh" label="确诊病例">确诊病例</el-radio>
        <el-radio v-model="form.ySzh" label="无症状感染者"
          >无症状感染者</el-radio
        >
        <el-radio v-model="form.ySzh" label="非排除的疑似病例"
          >非排除的疑似病例</el-radio
        >
        <el-radio v-model="form.ySzh" label="以上都不是">以上都不是</el-radio>
      </el-form-item>
      <el-form-item label="是否解除医学隔离观察" prop="yGl">
        <el-radio v-model="form.yGl" label="尚在隔离观察"
          >尚在隔离观察</el-radio
        >
        <el-radio v-model="form.yGl" label="健康监测期的密切接触者"
          >健康监测期的密切接触者</el-radio
        >
        <el-radio v-model="form.yGl" label="密切接触者">密切接触者</el-radio>
        <el-radio v-model="form.yGl" label="一般接触者">一般接触者</el-radio>
        <el-radio v-model="form.yGl" label="不属于医学隔离观察对象"
          >不属于医学隔离观察对象</el-radio
        >
      </el-form-item>
      <el-form-item label="疫苗接种情况" prop="ymStatus">
        <el-radio v-model="form.ymStatus" :label="0">0- 3针未完成</el-radio>
        <el-radio v-model="form.ymStatus" :label="1">1- 3针已接</el-radio>
      </el-form-item>

      <el-form-item label="会议报道日前7天症状" prop="yZh">
        <div>
          <el-checkbox-group v-model="form.yZh">
            <el-checkbox v-for="city in cities" :label="city" :key="city">{{
              city
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>

      <el-form-item label="如出现以上所列症状,是否排除疑似传染病" prop="yPc">
        <el-radio v-model="form.yPc" :label="0">是</el-radio>
        <el-radio v-model="form.yPc" :label="1">否</el-radio>
        <el-radio v-model="form.yPc" :label="2">无以上所列症状</el-radio>
      </el-form-item>
    </el-form>
    <el-button class="baocun" size="medium" type="primary" @click="save">
      保存
    </el-button>
  </el-dialog>

  <!-- 增加 -->
</template>
    
    <script>
import { saveActivityInfo, getrenwuList } from "@/api/yiqing";
import axios from "axios";
import { getToken } from "../../../common/utils/auth";
import { city } from "../js/city";
import { regionData } from "element-china-area-data";
import { number } from "echarts";

//赋值給级联选择器
const optionshhhh = regionData;
const cityOptions = [
  "发热",
  "乏力",
  "咳嗽或打喷嚏",
  "咽痛",
  "腹泻",
  "呕吐",
  "黄疸",
  "皮疹",
  "结膜充血",
  "都没有",
];
export default {
  name: "search",
  components: {
    // fuwenben,
  },
  data() {
    const validatesurfacePlot = (rule, value, callback) => {
      if (this.form.healthImg === "") {
        callback(new Error("请添加图片!!!!"));
      } else {
        callback();
      }
    };
    const validate = (rule, value, callback) => {
      if (this.form.tAddress === "") {
        callback(new Error("请选择途径地!!!!"));
      } else {
        callback();
      }
    };
    const validatesurfacePlotwo = (rule, value, callback) => {
      if (this.form.journeyImg === "") {
        callback(new Error("请添加图片!!!!"));
      } else {
        callback();
      }
    };
    const validatesurfacePlothree = (rule, value, callback) => {
      if (this.form.heSuanJianCeHistory === "") {
        callback(new Error("请添加核酸检测记录图片!!!!"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var contactPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      props: { multiple: true },
      startAddress: "",
      cities: cityOptions,
      optionswo: optionshhhh,
      optionthree: optionshhhh,
      dialogImageUrl: "",
      dialogVisible: false,
      imageUrltwo: "",
      imageUrlthree: "",
      heSuanJianCeHistory: "",
      imageUrlthreeS: "", //手写签名找
      labelPosition: "top",
      title: "",
      plateList: [],
      selectedIds: [],
      dialogFormVisible: false,
      options: [],
      value: [],
      list: [],
      loading: false,
      optionstwo: [],
      valuetwo: [],
      listtwo: [],
      loadingtwo: false,
      fileUrl: "", //视频url
      token: "",
      imageUrl: "",
      formData: {
        key: "",
        policy: "",
        OSSAccessKeyId: "",
        signature: "",
      },
      ruleForm: {
        checkPass: "",
      },
      videoForm: {
        Video: "",
      },
      options2: [],
      options3: [],
      aliyunform: {},
      fuwenben: "",
      form: {
        activityId: "",
        tAddress: 0,
      },
      activated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.play();
      },
      deactivated() {
        if (this.$refs.MaskVideo.src) this.$refs.MaskVideo.pause();
      },

      rules: {
        jcs14Address: [
          {
            required: true,
            message: "请输入去过的发生疫情的地级市和有扩散风险的毗邻地区及人员",
            trigger: "blur",
          },
        ],
        jcs21Address: [
          {
            required: true,
            message: "请输入存在接触史或者境外居住历",
            trigger: "blur",
          },
        ],
        wendu: [
          { required: true, message: "请输入温度", trigger: "blur" },
          { type: "number", message: "温度必须为数字值" },
        ],
        healthImg: [
          { required: true, trigger: "change", message: "请添加健康码图片" },
          { validator: validatesurfacePlot, trigger: "change" },
        ],
        journeyImg: [
          { required: true, trigger: "change", message: "请添加行程码图片" },
          { validator: validatesurfacePlotwo, trigger: "change" },
        ],
        heSuanJianCeHistory: [
          {
            required: true,
            trigger: "change",
            message: "请添加核酸检测记录图片",
          },
          { validator: validatesurfacePlothree, trigger: "change" },
        ],

        tAddress: [{ validator: validate, trigger: "change" }],
        ymStatus: [
          { required: true, message: "请选择疫苗接种情况", trigger: "change" },
        ],
        startAddress: [
          { required: true, message: "请选择出发地", trigger: "change" },
        ],
        activityId: [
          { required: true, message: "请选择未完成的任务", trigger: "change" },
        ],
      },
    };
  },
  created() {},
  // mounted() {
  //   this.list = this.states.map((item) => {
  //     return { value: `value:${item}`, label: `label:${item}` }
  //   })
  //   console.log(this.list)
  // },
  methods: {
    cascartwo() {
      let that = this;
      let hyIds = []; //所有选中的数据
      for (let i in that.$refs["cascadertwo"].getCheckedNodes()) {
        hyIds.push(that.$refs["cascadertwo"].getCheckedNodes()[i].pathLabels);
      }
      console.log(hyIds);
      let obj = "";
      hyIds.map((x) => {
        console.log(x);
        if (x.length == 3) {
          obj += x.join(" ") + ";";
          this.form.tAddress = obj;
          console.log(obj);
        }
      });
    },
    cascar(e) {
      let that = this;
      that.$set(
        that.form,
        "startAddress",
        that.$refs["cascader"].getCheckedNodes()[0].pathLabels[0] +
          " " +
          that.$refs["cascader"].getCheckedNodes()[0].pathLabels[1] +
          " " +
          that.$refs["cascader"].getCheckedNodes()[0].pathLabels[2]
      );
      console.log(that.form.startAddress);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    hand(res, file, fileList) {
      //上传行程吗
      this.imageUrlthree = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.journeyImg = res.data;
    },
    getrenwulist() {
      getrenwuList({
        participateId: this.form.participateId,
      })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            // let data = res.data;
            console.log(res.data);
            this.plateList = res.data;
            // this.total = data.total;
            // this.tableData = data.dataList;
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    hesuan(res, file, fileList) {
      //上传行程吗
      this.heSuanJianCeHistory = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.heSuanJianCeHistory = res.data;
      // 	OMIBZ-SQB6U-ZJQV6-4BBMK-IRGLT-S6BTK
    },
    handleAvatarSuccess(res, file, fileList) {
      //健康码
      this.imageUrltwo = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.healthImg = res.data;
    },
    handS(res, file, fileList) {
      //上传手写签名
      this.imageUrlthreeS = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.singature = res.data;
    },

    querySearch() {
      // let datas = "OMIBZ-SQB6U-ZJQV6-4BBMK-IRGLT-S6BTK";
      // axios
      //   .get("https://apis.map.qq.com/ws/district/v1/list", {
      //     params: {
      //       key: datas,
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res, "ditu");
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // An highlighted block
      const KEY = "OMIBZ-SQB6U-ZJQV6-4BBMK-IRGLT-S6BTK";
      const url = "https://apis.map.qq.com/ws/district/v1/list";
      // const address =
      //   this.from.province +
      //   this.from.city +
      //   this.from.district +
      //   this.from.address;
      this.$jsonp(url, {
        key: KEY,
        // address: address,
        output: "jsonp",
      }).then((res) => {
        console.log(res.result);
        // this.optionthree = res.result;
      });
    },
    handlePictureCardPreview(file) {
      this.imageUrltwo = file.url;
    },

    handleAvatarSuccessone(res, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(res);
      this.form.headPortrait = res.data;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return true;
    },
    showEdit(row) {
      let t = getToken();
      this.token = t;
      console.log(this.token);
      if (!row) {
        this.title = "添加";
      } else {
        // this.form = Object.assign({}, row);
        this.title = "代提交";
        this.dialogFormVisible = true;
        this.form = {
          participateId: row.id, //嘉宾id
          id: row.id, //住建id
          // activityId: row.id, //活动id
          idcard: row.idcard,
          phone: row.phone,
          workGroupName: row.workGroupName,
          userTypeName: row.userTypeName,
          yName: row.username,
          yMa: "绿码",
          ymStatus: 1,
          yPc: 2,
          jcs14: "否",
          jcs21: "都没有",
          ySq: -1,
          ySzh: "以上都不是",
          yGl: "不属于医学隔离观察对象",
          yZh: ["都没有"],
        };
        this.getrenwulist();
      }
    },
    close() {
      this.dialogFormVisible = false;

      this.$refs["form"].resetFields();
      this.imageUrltwo = "";
      this.imageUrlthree = "";
      this.heSuanJianCeHistory = "";
      this.startAddress = "";
      this.selectedIds = [];
    },
    save() {
      // this.$router.push('/other/workflow')

      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.form.id) {
            this.form.yZh = String(this.form.yZh);
            let func = saveActivityInfo;
            func(this.form)
              .then((res) => {
                console.log(res);
                let title = "代上传";
                if (res.code == 0) {
                  this.$alert(res.msg, title + "失败", {
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                  });
                  this.close();
                } else if (res.code == 1) {
                  this.$alert(res.msg, title + "成功", {
                    dangerouslyUseHTMLString: true,
                    type: "success",
                  }).then(() => {
                    this.close();
                    this.$emit("fetch-data");
                  });
                }
              })
              .catch((res) => {
                console.log(res);
              });
          } else {
            this.form.cuserId = this.form.cuserId + 0;
            const msg = doAdd(this.form);
            console.log(this.form);
            msg.then((res) => {
              console.log(res.msg);
              if (res.msg === "新增成功") {
                this.$baseMessage(
                  res.msg,
                  "success",
                  "vab-hey-message-success"
                );
                this.$emit("fetch-data");
                this.close();
                this.$router.push({
                  path: "/huodong/huodonglist",
                });
              }
            });
          }
        }
      });
    },
    async fetchDatathree() {
      let that = this;
      let queryForm = {};
      const res = await AssignEmployees(queryForm);
      console.log(res.data.list);
      this.options3 = res.data.list;
      if (!this.form.id) {
        this.form.userId = this.options3.filter(
          (item) => item.nickName === res.data.nickName
        )[0].id;
      }

      console.log(res.data.nickName);
    },
    async fetchData() {
      let queryForm = {};
      const res = await getListtwo(queryForm);

      this.options2 = res.articleCategoryList;
    },

    nickNameChange() {
      this.$forceUpdate();
    },
  },
};
</script>
    <style>
/* .huakuai {
        position: relative;
        left: 96%;
      } */
.avatar-uploader .el-upload {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  width: 178px;
  height: 178px;
  font-size: 28px;
  line-height: 178px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  display: block;
  width: 178px;
  height: 178px;
}
/* .videssad {
        display: block;
        width: 360px;
        height: 178px;
      } */

.baocun {
  width: 100%;
  height: 100%;
}
</style>
    